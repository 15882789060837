import type { AxiosRequestConfig } from 'axios'

import router from '@/router'
import axios from 'axios'
import {
  buildKeyGenerator,
  buildWebStorage,
  setupCache,
} from 'axios-cache-interceptor'

import { useIdentityStore } from '@/stores/identity'

const environment = import.meta.env.VITE_ENVIRONMENT
interface RetryConfig extends AxiosRequestConfig {
  retry: number
  retryDelay: number
  retryableStatusList: number[]
}
export const retryableConfig: RetryConfig = {
  retry: 3,
  retryDelay: 1000,
  retryableStatusList: [503],
}
export const createClient = (
  config: AxiosRequestConfig | RetryConfig = retryableConfig
) => {
  const IdentityStore = useIdentityStore()
  const instance = axios.create({
    baseURL: import.meta.env.VITE_BFSK_API_BASE_URL || '/api',
    timeout: 2 * 60 * 1000, // 2 minutes
    headers: {
      ...(IdentityStore.accountId && {
        'X-Account-Id': IdentityStore.accountId,
      }),
      ...(IdentityStore.userId && { 'X-User-Id': IdentityStore.userId }),
      ...(IdentityStore.token && { Authorization: IdentityStore.token }),
      'Content-Type': 'application/json',
    },
    transformResponse: [
      function (data) {
        const parsedDataData = data && (JSON.parse(data)?.data ?? null) // ! When server successfully returns subdata, which may include errors, pass along the subdata.
        const parsedData = data && JSON.parse(data) // ! When server does not successfully return data, pass along the data.
        if (parsedDataData) {
          return [...(parsedDataData && parsedDataData)]
        } else {
          return parsedData
        }
      },
    ],
    ...config,
  })
  const client = setupCache(instance, {
    storage: buildWebStorage(localStorage, 'bfsk-axios-cache'),
    methods: ['get'],
    generateKey: buildKeyGenerator((request) => ({
      method: request.method,
      baseURL: request.baseURL,
      data: request.data,
      params: request.params,
      url: request.url,
      accountId: IdentityStore.accountId,
    })),
    ...(environment === 'local' && { debug: console.log }),
  })
  // ! Handle Unauthorized & Retryable
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        IdentityStore.logout()
        router.push({
          name: 'login',
          query: {
            redirect: router?.currentRoute?.value?.fullPath ?? '/',
          },
        })
      } else {
        // TODO: Handle Other Errors
      }
      if (!error.config || !error.config.retry) {
        return Promise.reject(error)
      }
      if (
        (error.config?.retryableStatusList ?? [503]).includes(
          error?.response?.status
        )
      ) {
        error.config.retry -= 1
        const delayRetryRequest = new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve()
          }, error.config.retryDelay || 1000)
        })
        return delayRetryRequest.then(() => client.request(error.config))
      }
      return Promise.reject(error)
    }
  )
  return client
}
