<script setup lang="ts">
  import { useRoute, useRouter } from 'vue-router'
  import { computed } from 'vue'

  import AccountDropdown from '@/components/AccountDropdown.vue'
  import LoaderGlobal from '@/components/LoaderGlobal.vue'
  import ThemeSwitch from '@/components/ThemeSwitch.vue'
  import Menubar from 'primevue/menubar'

  import { useIdentityStore } from '@/stores/identity'

  const router = useRouter()
  const route = useRoute()
  const IdentityStore = useIdentityStore()
  const MenuItems = computed(() => {
    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'About',
        url: '/about',
      },
      {
        label: 'User Settings',
        url: '/user-settings',
        visible: IdentityStore.isAuthenticated,
      },
      {
        label: 'Product Manager',
        url: '/product/manage',
        visible: IdentityStore.isAuthenticated,
      },
      {
        label: 'Promotion Manager',
        url: '/promotion/manage',
        visible: IdentityStore.isAuthenticated,
      },
      {
        label: 'Login',
        url: '/login',
        visible: !IdentityStore.isAuthenticated,
      },
      {
        label: 'Logout',
        command: () => {
          if (IdentityStore.isAuthenticated) {
            IdentityStore.logout()
            if (
              route.path !== '/login' &&
              (route.meta?.requiresAuth ?? false)
            ) {
              router.push({
                name: 'login',
                query: {
                  redirect: route.fullPath,
                },
              })
            }
          }
        },
        visible: IdentityStore.isAuthenticated,
      },
    ]
  })
</script>

<template>
  <div class="main-navigation">
    <Menubar
      class="w-full"
      :model="MenuItems"
      orientation="horizontal"
      :pt="{
        root: {
          class:
            'relative flex items-center gap-6 px-2 md:px-6 min-h-[3rem] shadow-md bg-surface-0 dark:bg-surface-900 ring-1 ring-inset ring-surface-100 dark:ring-surface-800 ring-offset-0 w-full',
        },
      }"
    >
      <template #start>
        <span
          class="md:show text-md hidden font-bold uppercase md:flex lg:text-xl"
          >Bläckfisk</span
        >
      </template>
      <template #end>
        <div class="flex">
          <AccountDropdown />
          <ThemeSwitch class="hidden w-min xs:flex" />
        </div>
      </template>
    </Menubar>
  </div>
  <LoaderGlobal />
</template>

<script lang="postcss" scoped></script>
