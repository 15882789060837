import type { Ref } from 'vue'

import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

export const useLoadingStore = defineStore('counter', () => {
  const loaders: Ref<Set<string>> = ref(new Set())
  const timeouts = new Map<string, NodeJS.Timeout>()
  function startLoader(key: string, timeout = 10000) {
    loaders.value.add(key)
    // ! Default 10 second timeout. If expected to last longer, gotta pass the expectation!
    const timerId = setTimeout(() => {
      loaders.value.delete(key)
      timeouts.delete(key)
    }, timeout)
    timeouts.set(key, timerId)
  }
  function stopLoader(key: string) {
    loaders.value.delete(key)
    clearTimeout(timeouts.get(key))
    timeouts.delete(key)
  }
  const isLoaderRunning = (key: string) => {
    return loaders.value.has(key)
  }
  const isLoading = computed(() => {
    return loaders.value.size > 0
  })

  return { loaders, startLoader, stopLoader, isLoading, isLoaderRunning }
})
