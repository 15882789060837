<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import { useIdentityStore } from '@/stores/identity'

  const IdentityStore = useIdentityStore()
  const { userName } = storeToRefs(IdentityStore)
</script>

<template>
  <main class="h-[calc(100vh-8rem)] w-full place-content-center">
    <div class="text-center text-5xl font-bold">
      Hello, {{ userName || 'Friend' }}
    </div>
  </main>
</template>
