import router from '@/router'

import { useIdentityStore } from '@/stores/identity'

export default {
  install() {
    const IdentityStore = useIdentityStore()
    router.beforeEach((to) => {
      if (to.meta.requiresAuth && !IdentityStore.isAuthenticated) {
        IdentityStore.returnUrl = to.fullPath
        return {
          name: 'login',
        }
      }
    })
  },
}
