import { usePrinterStore } from '@/stores/printer'

export const usePrinters = () => {
  const PrinterStore = usePrinterStore()
  const refreshPrinters = () => {
    // ? Will these run only when needed?
    // * Caching controlled by server header.
    PrinterStore.refreshPrinters()
    PrinterStore.refreshPapers()
  }
  return {
    refreshPrinters,
  }
}
