<script setup lang="ts">
  import AppSettings from '@/components/AppSettings.vue'
  import { useDialog } from 'primevue/usedialog'

  const dialog = useDialog()
  const onOpenSettings = () => {
    dialog.open(AppSettings, {
      props: {
        header: 'App Settings',
        style: {
          'width': '75vw',
          'max-width': '75vw',
        },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw',
        },
        modal: true,
        draggable: false,
      },
    })
  }
</script>

<template>
  <div class="absolute bottom-4 left-4">
    <i
      class="pi pi-cog cursor-pointer text-xl"
      title="Application Settings"
      @click="onOpenSettings"
    />
  </div>
</template>

<style lang="postcss"></style>
