import type { UserAccount } from '@/services/blackfisk/auth'
import type { Ref } from 'vue'

import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { authLogin } from '@/services/blackfisk/auth'

export const useIdentityStore = defineStore(
  'identity',
  () => {
    // const environment = import.meta.env.VITE_ENVIRONMENT
    // const version = '2.7.23'
    // const user = localStorage.getItem(`bfsk-login-${environment}-${version}`)
    const getToken = () => {
      return localStorage.getItem('token') || ''
    }
    const userId = ref(0)
    const userName = ref('')
    const userEmail = ref('')
    const accountId = ref(0)
    const roles: Ref<string[]> = ref([])
    const accounts: Ref<UserAccount[]> = ref([])
    const token = ref(getToken())
    const isAuthenticated = computed(() => token.value.length > 0)
    const returnUrl: Ref<string> = ref('')
    const activeAccount = computed(() => {
      const accountMap = accounts.value.reduce(
        (accumulator: Record<string, UserAccount>, account) => {
          accumulator[`${account.id}`] = account
          return accumulator
        },
        {}
      )
      return accountMap[accountId.value]
    })

    const login = async ({ email = '', password = '' } = {}) => {
      const { user, errors } = await authLogin({
        email,
        password,
      })
      if ((user?.id ?? 0) > 0) {
        if (user.token) {
          localStorage.setItem('token', user.token)
          token.value = user.token
        }
        userName.value = `${user.firstName}${user.lastName ? ' ' + user.lastName : ''}`
        userEmail.value = user.email
        if ((user?.masterAccountId ?? 0) > 0) {
          accountId.value = user.masterAccountId
        }
        if ((user?.accounts ?? []).length > 0) {
          accounts.value = user.accounts.map((account) => ({
            id: account.id,
            name: account.name,
            ianaTimeZoneString: account.ianaTimeZoneString,
          }))
        }
        if ((user?.roles ?? []).length > 0) {
          roles.value = user.roles.map((role) => role.role)
        }
        // * Set ID last so data is available for any watchers.
        userId.value = user.id
      } else {
        // TODO: Handle Errors
        console.error(errors)
      }
    }
    const logout = async () => {
      userName.value = ''
      userEmail.value = ''
      accountId.value = 0
      roles.value = []
      accounts.value = []
      token.value = ''
      localStorage.setItem('token', '')
      // * Set ID last so data is available for any watchers.
      userId.value = 0
    }
    return {
      accountId,
      userId,
      userName,
      userEmail,
      roles,
      accounts,
      token,
      login,
      logout,
      isAuthenticated,
      returnUrl,
      activeAccount,
    }
  },
  {
    persist: {
      storage: localStorage,
    },
  }
)
