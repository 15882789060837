<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import ProgressBar from 'primevue/progressbar'

  import { useLoadingStore } from '@/stores/loading'

  const LoadingStore = useLoadingStore()
  const { isLoading } = storeToRefs(LoadingStore)
</script>

<template>
  <ProgressBar
    v-show="isLoading"
    mode="indeterminate"
    :showValue="false"
    style="height: 3px"
  ></ProgressBar>
  <div v-show="!isLoading" class="blackfisk-dark h-[3px]"></div>
</template>
